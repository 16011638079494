/* eslint-disable no-underscore-dangle */
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: '',
    },
    right: { /** align input right */
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Array,
      default: () => [],
    },
    wetInputClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    corner: {
      type: Boolean,
      default: false,
    },
    dark: { /** label and messages - white text */
      type: Boolean,
      default: false,
    },
    labeled: { /** allways show label */
      type: Boolean,
      default: false,
    },
    transparent: { /** input backgrount is transparent */
      type: Boolean,
      default: false,
    },
    hideDetails: { /** auto hide errors if it no has */
      type: [Boolean, String],
      default: false,
    },
    primary: { /** border color */
      type: Boolean,
      default: false,
    },
    elevated: { /** shadowed input */
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    /** set label to primary color, and large when label on border */
    accent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _id() {
      return this.id || `${this.type}-${this._uid}`;
    },
  },
};
