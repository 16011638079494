<template>
  <inputControl
    v-model="inputValue"
    v-bind="propsNoValue"
    @update:focused="(v) => focused = v"
  />
</template>

<script>
import inputControl from '@/components/ui/inputControlUi.vue';
import inputMixin from '@/mixins/ui/inputMixin';

export default {
  mixins: [inputMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    inputControl,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        const numVal = val === '' ? undefined : val;
        this.$emit('input', numVal);
      },
    },
    propsNoValue() {
      const props = {
        ...this.$props,
        focused: this.focused,
      };
      delete props.value;
      return props;
    },
  },
};
</script>

<style>

</style>
