<template>
  <div
    class="input-control"
    :class="{
      'dark': dark,
      'labeled': labeled,
      'filled': `${value}`.length > 0,
      'transparent': transparent,
      'primary': primary || errors.length > 0,
      'elevated': elevated,
      'focused': focused,
      'accent': accent,
    }"
  >
    <label v-if="label" class="input-label" :for="_id">{{ label }}</label>
    <div
      class="input-field-control"
      :class="{
        'corner': corner,
      }"
    >
      <div class="input-field">
        <slot>
          <input
            :type="type"
            :placeholder="labeled && placeholder"
            :id="_id"
            v-model="inputValue"
            :class="{
              'input-right': right,
              [inputClass]: !!inputClass,
            }"
            :disabled="disabled"
            :inputmode="inputmode"
            @focus="$emit('update:focused', true)"
            @blur="$emit('update:focused', false)"
          >
        </slot>
      </div>
      <span v-if="append" class="input-append">
        {{ append }}
      </span>
      <span v-if="$slots.append" class="input-append">
        <slot name="append"></slot>
      </span>
      <div class="input-bg">
        <div class="input-bg-left"></div>
        <div class="input-bg-center">
          <label class="input-bg-label ellipsis w-100" :for="_id">{{ label }}</label>
        </div>
        <div class="input-bg-right"></div>
      </div>
    </div>
    <ul
      v-if="hideDetails !== true"
      class="list-unstyled mb-0 line-height-small input-errors"
      :class="{
        'empty-details': hideDetails !== 'auto'
      }"
    >
      <transition-group name="collapse">
        <li
          v-for="(error, i) in errors"
          :key="`error-${i}`"
          class="small text-primary pt-2 pl-3"
        >{{ error }}</li>
      </transition-group>
    </ul>
  </div>
</template>
<script>
import inputMixin from '@/mixins/ui/inputMixin';

export default {
  mixins: [inputMixin],
  props: {
    value: {
      type: [String, Number, Array, Object, Date],
      default: undefined,
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        const numVal = val === '' ? undefined : val;
        this.$emit('input', numVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-control {
    &.elevated .input-field-control {
      box-shadow: 0px 2px .5rem #{'rgba(var(--input-control-shadow-color), 0.2)'};
      transition: box-shadow .3s ease-in-out;
    }

    &.elevated .input-field-control:focus,
    &.elevated .input-field-control:hover,
    &.elevated.focused .input-field-control {
      box-shadow: 0px 5px .5rem #{'rgba(var(--input-control-shadow-color), 0.3)'};
    }
  }
  .dark {
    label {
      color: white;
    }
  }
  .input-field-control {
    position: relative;
    display: flex;
    align-items: baseline;
    padding: .4rem var(--padding-horizontal-input-fields);
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid var(--border-input-color);
    transition: all .3s ease-in-out;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  }
  .transparent .input-field-control {
    background-color: transparent;
  }
  .input-field {
    flex: 1;
    display: flex;
    overflow: hidden;
    &>* {
      flex: 1;
      width: 100%;
      min-height: 1.5rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding: 0;
      line-height: 1rem;
    }
  }
  .input-append {
    padding-left: .5rem;
  }
  .input-right {
    text-align: right;
  }
  .corner {
    border-radius: 0px;
  }
  .input-label {
    padding-left: 1rem;
    margin-bottom: 0;
    display: none;
    padding-bottom: 0.5rem;
  }
  .labeled .input-label {
    display: block;
  }
  .input-bg {
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    margin: -1px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
  }
  .labeled .input-bg .input-bg-label {
    display: none;
  }
  .filled .input-bg .input-bg {
    &-center {
      border-width: 0 0 1px 0;
    }
    &-label {
      padding: 0 .5rem;
      font-size: .75rem;
      transform: translateY(-50%);
    }
  }

  .filled.accent .input-bg-label {
    font-size: 1.25rem;
    font-weight: bold;
    color: #{'rgb(var(--input-control-color))'};
  }

  .dark.transparent .input-bg .input-bg-label,
  .dark.transparent input,
  .dark.transparent .input-append {
    color: white;
  }

  ul.input-errors.empty-details {
    min-height: 1.5rem;
  }

  @media (max-width: 768px) {
    .input-label {
      font-size: .75rem;
      padding-bottom: 0.25rem;
    }
  }
</style>
